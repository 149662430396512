import React from 'react'
import { SpecialisationHiringProcess } from '@src/interfaces/hiringProccess'
import { useTable } from '@src/components/Table/hooks'
import { hiringProcessesTableRequests } from '@src/api/hiringProcess'
import AdjustableTable from '@src/components/Table/AdjustableTable'
import { TableNames } from '@src/constants/table'
import {
  HiringProcessesNameColumn,
  HiringProcessesNoteColumn,
  HiringProcessActionHandler,
  createHiringProcessesActions,
} from '@src/constants/columns/hiringProcesses'
import { RowInterface } from '@src/interfaces/data'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { SpecialisationInterface } from '@src/interfaces/roles'
import { pathToUrl } from '@src/utils/router'
import { navigateTo } from '@src/actions/RouterActions'
import { ROUTES } from '@src/constants/routes'

const HiringProcessesRow = (
  onEdit: HiringProcessActionHandler,
): RowInterface<SpecialisationHiringProcess> => ({
  cells: [
    {
      ...HiringProcessesNameColumn,
      width: 250,
    },
    {
      ...HiringProcessesNoteColumn,
      width: 250,
    },
    {
      ...createHiringProcessesActions(onEdit),
      width: 56,
    },
  ],
})

export const HiringProcessesTable = () => {
  const { values } = useLapeContext<SpecialisationInterface>()
  const table = useTable<SpecialisationHiringProcess>(
    hiringProcessesTableRequests(values.id),
    [
      {
        columnName: 'status',
        filters: [{ id: 'active', name: 'active' }],
      },
    ],
  )
  const handleEdit = (data: SpecialisationHiringProcess) => {
    navigateTo(
      pathToUrl(ROUTES.FORMS.SPECIALISATION_HIRING_PROCESS.GENERAL, {
        hiringProcessId: data.id,
        specialisationId: values.id,
      }),
    )
  }
  return (
    <AdjustableTable<SpecialisationHiringProcess>
      name={TableNames.SpecialisationHiringProcess}
      dataType="Hiring processes"
      row={HiringProcessesRow(handleEdit)}
      noDataMessage="Hiring stages will appear here."
      onRowClick={handleEdit}
      {...table}
    />
  )
}
