import React from 'react'
import { MoreBar, TableWidget } from '@revolut/ui-kit'
import { useCustomHiringProcessFlag } from '@src/pages/Forms/SpecialisationForm/HiringProcesses/utils'
import HiringProcess from '@src/pages/Forms/SpecialisationForm/HiringProcess/HiringProcess'
import { HiringProcessesTable } from '@src/pages/Forms/SpecialisationForm/HiringProcesses/HiringProcessesTable'
import { CloneHiringProcess } from '@src/pages/Forms/SpecialisationForm/HiringProcesses/CloneHiringProcess'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { SpecialisationInterface } from '@src/interfaces/roles'

export const HiringProcessesPage = () => {
  const { values } = useLapeContext<SpecialisationInterface>()
  const customHiringProcesses = useCustomHiringProcessFlag()
  if (!customHiringProcesses) {
    return <HiringProcess />
  }
  return (
    <TableWidget>
      <TableWidget.Actions>
        <MoreBar>
          <CloneHiringProcess
            hiringProcess={values.default_hiring_process}
            name={values.name}
            variant="more-bar"
          />
        </MoreBar>
      </TableWidget.Actions>
      <TableWidget.Table>
        <HiringProcessesTable />
      </TableWidget.Table>
    </TableWidget>
  )
}
