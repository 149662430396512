import React from 'react'
import { CellTypes, ColumnInterface } from '@src/interfaces/data'
import { SpecialisationHiringProcess } from '@src/interfaces/hiringProccess'
import { selectorKeys } from '@src/constants/api'
import { HStack, IconButton, Tag, Text, Token } from '@revolut/ui-kit'
import { CloneHiringProcess } from '@src/pages/Forms/SpecialisationForm/HiringProcesses/CloneHiringProcess'

type HiringProcessesColumnInterface = ColumnInterface<SpecialisationHiringProcess>

export const HiringProcessesNameColumn: HiringProcessesColumnInterface = {
  type: CellTypes.text,
  idPoint: 'name',
  dataPoint: 'name',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Name',
}

export const HiringProcessesNoteColumn: HiringProcessesColumnInterface = {
  type: CellTypes.insert,
  idPoint: 'description',
  dataPoint: 'description',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Note',
  insert: ({ data }) => {
    if (data.is_default) {
      return (
        <HStack gap="s-8" align="center">
          <Text>{data.description}</Text>
          <Tag color={Token.color.lightBlue}>Default</Tag>
        </HStack>
      )
    }
    return data.description
  },
}

export type HiringProcessActionHandler = (data: SpecialisationHiringProcess) => void

export const createHiringProcessesActions = (
  onEdit: HiringProcessActionHandler,
): HiringProcessesColumnInterface => ({
  type: CellTypes.insert,
  idPoint: '',
  dataPoint: '',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  insert: ({ data }) => {
    return (
      <HStack gap="s-8">
        <CloneHiringProcess
          hiringProcess={data}
          name={data.name}
          variant="duplicate-icon"
        />
        <IconButton
          color={Token.color.greyTone20}
          useIcon="Pencil"
          onClick={() => onEdit(data)}
        />
      </HStack>
    )
  },
  title: 'Actions',
})
