import React from 'react'
import { JobPostingInterface } from '@src/interfaces/jobPosting'
import { useLapeContext } from '@src/features/Form/LapeForm'
import {
  useGetSpecialisationHiringProcess,
  useGetActiveSpecialisationHiringProcessList,
} from '@src/api/hiringProcess'
import {
  Button,
  ItemSkeleton,
  SelectInput,
  Subheader,
  TextButton,
  VStack,
} from '@revolut/ui-kit'
import { PageBody } from '@src/components/Page/PageBody'
import { SpecialisationHiringProcess } from '@src/interfaces/hiringProccess'
import HiringProcessDetails from '@src/components/HiringProcessDetails/HiringProcessDetails'
import { PageActions } from '@src/components/Page/PageActions'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import { useLocation, useParams } from 'react-router-dom'
import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import { JobPostingLocationState } from '@src/features/JobPostingFlow/types'
import { FormattedMessage } from 'react-intl'

const HiringProcessSelector = () => {
  const { values } = useLapeContext<JobPostingInterface>()
  const { data, isLoading } = useGetActiveSpecialisationHiringProcessList(
    values.specialisation?.id,
  )
  if (!values.hiring_process && data.length) {
    values.hiring_process = data.find(({ is_default }) => is_default) ?? null
  }
  const options = data.map(specialisationHiringProcess => ({
    key: specialisationHiringProcess.id,
    label: specialisationHiringProcess.name,
    value: specialisationHiringProcess,
  }))
  const value = options?.find(
    option => values.hiring_process?.id === option.value.id,
  )?.value
  const handleChange = (hiringProcess: SpecialisationHiringProcess | null) => {
    if (hiringProcess) {
      values.hiring_process = hiringProcess
    }
  }
  return (
    <SelectInput
      label="Hiring process"
      options={options}
      value={value}
      onChange={handleChange}
      pending={isLoading}
    />
  )
}

export const HiringProcessList = () => {
  const { values } = useLapeContext<JobPostingInterface>()
  const { data, isLoading } = useGetSpecialisationHiringProcess(
    values.specialisation?.id,
    values.hiring_process?.id,
  )
  if (isLoading) {
    return (
      <VStack gap="s-8">
        <ItemSkeleton />
        <ItemSkeleton />
        <ItemSkeleton />
        <ItemSkeleton />
      </VStack>
    )
  }
  return (
    <HiringProcessDetails
      hiringProcessRounds={data?.hiring_process_stages ?? []}
      loading={isLoading}
    />
  )
}

export const HiringProcess = () => {
  const { values } = useLapeContext<JobPostingInterface>()
  const params = useParams()
  const { state: locationState } = useLocation<JobPostingLocationState>()
  return (
    <>
      <PageBody>
        <HiringProcessSelector />
        <Subheader variant="nested">
          <Subheader.Title>
            <FormattedMessage
              id="recruitment.jobPosting.hiringProcess"
              defaultMessage="Custom hiring process for this job posting can be created inside {specialisationName} specialisation."
              values={{ specialisationName: values.specialisation?.name }}
            />
            <TextButton
              use={InternalLink}
              to={pathToUrl(ROUTES.FORMS.SPECIALISATIONS.HIRING_PROCESS, {
                id: values.specialisation?.id,
              })}
              target="_blank"
            >
              Create custom stages
            </TextButton>
          </Subheader.Title>
        </Subheader>
        <HiringProcessList />
      </PageBody>
      <PageActions>
        <Button
          use={InternalLink}
          onClick={() => {
            navigateTo(
              pathToUrl(ROUTES.FORMS.JOB_POSTING_FLOW.APPLICATION_FORM, params),
              locationState,
            )
          }}
          variant="secondary"
          elevated
        >
          Back
        </Button>
        <NewSaveButtonWithPopup<JobPostingInterface>
          useValidator
          hideWhenNoChanges={false}
          successText="Job posting saved successfully"
          onAfterSubmit={() => {
            navigateTo(
              pathToUrl(ROUTES.FORMS.JOB_POSTING.PREVIEW, {
                id: values.id,
                specId: values.specialisation?.id,
              }),
            )
          }}
        >
          Submit
        </NewSaveButtonWithPopup>
      </PageActions>
    </>
  )
}
