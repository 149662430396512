import {
  GetRequestData,
  GetRequestInterface,
  IdAndName,
  RequestInterface,
  RequestInterfaceNew,
  Statuses,
  TableRequestInterface,
} from '@src/interfaces'
import { api, apiWithoutHandling } from '@src/api/index'
import { API } from '@src/constants/api'
import { filterSortPageIntoQuery } from '@src/utils/table'
import {
  CandidateBulkArchiveInterface,
  CandidateBulkEditInterface,
  CandidateBulkEmailsInterface,
  CandidateBulkMoveStageInterface,
  CandidateBulkSendOnlineTestInterface,
  CandidateBulkSnoozeInterface,
  CandidateBulkUnsnoozeInterface,
  CandidateDocumentInterface,
  CandidateFormInterface,
  CandidateStatsInterface,
  ConsentRequiredInterface,
  EmailThreadInterface,
  HiringProcessInterface,
  OnlineTestInterface,
  SendCandidateEmailInterface,
  SendCandidateEmailPayloadInterface,
  SpecialisationHiringProcess,
  SpecialisationsHiringProcessOverviewInterface,
  StageReference,
} from '@src/interfaces/hiringProccess'
import { AxiosPromise, AxiosResponse } from 'axios'
import { FileInterface } from '@src/interfaces/files'
import { deleteFile, uploadFile } from '@src/api/files'
import { isFile } from '@src/utils/files'
import { useFetch } from '@src/utils/reactQuery'
import {
  FetchDataQueryInterface,
  FilterByInterface,
  SORT_DIRECTION,
} from '@src/interfaces/data'
import { getCommentsAPI } from '@src/api/comments'
import {
  CandidateAddDocumentInterface,
  InterviewStageInterface,
} from '@src/interfaces/interviewTool'
import { TimelineEventInterface } from '@src/interfaces/timeline'
import { useMemo } from 'react'
import { ApplicationSectionInterface } from '@src/interfaces/applicationQuestions'
import { QueryClient } from 'react-query'

export const hiringProcessesStagesRequests: RequestInterface<HiringProcessInterface> = {
  getItems: async ({ sortBy, filters, page }) =>
    api.get(`${API.HIRING_PROCESS}${API.HIRING_STAGES}`, {
      params: filterSortPageIntoQuery(sortBy, filters, page),
    }),
  getItem: async id => api.get(`${API.HIRING_PROCESS}${API.HIRING_STAGES}/${id}`),
  patchItem: async (data, requisitionId) =>
    api.patch(`${API.HIRING_PROCESS}${API.HIRING_STAGES}/${requisitionId}`, data),
  deleteItem: async id =>
    api.post(`${API.HIRING_PROCESS}${API.HIRING_STAGES}/${id}/archive`),
  postItem: async data => api.post(`${API.HIRING_PROCESS}${API.HIRING_STAGES}`, data),
  getExport: async (exportType, filterQuery) =>
    api.get(`${API.HIRING_PROCESS}${API.HIRING_STAGES}/${exportType}`, {
      params: filterQuery,
      responseType: 'blob',
    }),
}

export const formHiringProcessStagesRequest: RequestInterfaceNew<HiringProcessInterface> =
  {
    get: async ({ id }) => api.get(`${API.HIRING_PROCESS}${API.HIRING_STAGES}/${id}`),
    update: async (data, { id }) =>
      apiWithoutHandling.patch(`${API.HIRING_PROCESS}${API.HIRING_STAGES}/${id}`, data),
    submit: async data =>
      apiWithoutHandling.post(`${API.HIRING_PROCESS}${API.HIRING_STAGES}`, data),
    delete: async ({ id }) =>
      api.post(`${API.HIRING_PROCESS}${API.HIRING_STAGES}/${id}/archive`),
  }

export const formHiringProcessStagesRoleRequest: RequestInterfaceNew<HiringProcessInterface> =
  {
    get: async ({ id }) => api.get(`${API.HIRING_PROCESS}/roleHiringStages/${id}`),
    update: async (data, { id }) =>
      apiWithoutHandling.patch(`${API.HIRING_PROCESS}/roleHiringStages/${id}`, data),
    submit: async data =>
      apiWithoutHandling.post(`${API.HIRING_PROCESS}/roleHiringStages`, data),
    delete: async ({ id }) =>
      api.post(`${API.HIRING_PROCESS}/roleHiringStages/${id}/archive`),
  }

export const formHiringProcessStagesSpecialisationRequest: RequestInterfaceNew<HiringProcessInterface> =
  {
    get: async ({ id }) =>
      api.get(`${API.HIRING_PROCESS}/specialisationHiringStages/${id}`),
    update: async (data, { id }) =>
      apiWithoutHandling.patch(
        `${API.HIRING_PROCESS}/specialisationHiringStages/${id}`,
        data,
      ),
    submit: async data =>
      apiWithoutHandling.post(`${API.HIRING_PROCESS}/specialisationHiringStages`, data),
    delete: async ({ id }) =>
      api.post(`${API.HIRING_PROCESS}/specialisationHiringStages/${id}/archive`),
  }

export const sendCandidateEmail = (
  candidateId: string | number,
  data: SendCandidateEmailPayloadInterface,
  emailId?: string,
) =>
  apiWithoutHandling.post<SendCandidateEmailInterface>(
    `/interviews/candidates/${candidateId}/emails`,
    {
      ...data,
      attachments: data.attachments || [],
      reply_email: emailId
        ? {
            id: emailId,
          }
        : null,
    } as SendCandidateEmailPayloadInterface,
  )

export const candidateEmailsRequests: RequestInterfaceNew<SendCandidateEmailInterface> = {
  get: async ({ id }) => api.get(`/interviews/candidates/${id}/emails`),
  update: async (data, { id }) =>
    apiWithoutHandling.patch(`/interviews/candidates/${id}/emails`, data),
  submit: async (data, { candidateId, emailId }) => {
    let files: AxiosResponse<FileInterface>[] | undefined
    if (data.attachments) {
      files = await Promise.all(
        data.attachments.map(file =>
          isFile(file)
            ? uploadFile(file, 'candidate_email')
            : new Promise<AxiosResponse<FileInterface>>(resolve => {
                resolve({ data: file } as AxiosResponse<FileInterface>)
              }),
        ),
      )
    }

    return sendCandidateEmail(
      candidateId!,
      {
        ...data,
        attachments: files?.map(item => ({ id: item.data.id })),
      } as SendCandidateEmailPayloadInterface,
      emailId,
    )
  },
}

export const bulkCandidatesEmailsRequests: RequestInterfaceNew<SendCandidateEmailInterface> =
  {
    get: async ({ id }) => api.get(`/interviews/candidates/${id}/emails`),
    update: async (data, { id }) =>
      apiWithoutHandling.patch(`/interviews/candidates/${id}/emails`, data),
    submit: async data => {
      let files: AxiosResponse<FileInterface>[] | undefined
      if (data.attachments) {
        files = await Promise.all(
          data.attachments.map(file =>
            isFile(file)
              ? uploadFile(file, 'candidate_email')
              : new Promise<AxiosResponse<FileInterface>>(resolve => {
                  resolve({ data: file } as AxiosResponse<FileInterface>)
                }),
          ),
        )
      }

      return apiWithoutHandling.post<SendCandidateEmailInterface>(
        `/candidateBulkEmails`,
        {
          ...data,
          attachments:
            files?.map(file => ({
              id: file?.data?.id,
            })) || [],
        } as SendCandidateEmailInterface,
      )
    },
  }

export const cancelCandidateEmail = (candidateId: number, emailId: number) =>
  apiWithoutHandling.patch(
    `/interviews/candidates/${candidateId}/emails/${emailId}/cancel`,
  )

export const useGetCandidateForms = (id?: number | string) =>
  useFetch<CandidateFormInterface[]>(
    id ? `/interviews/candidates/${id}/forms` : null,
    'v1',
  )

export const useGetCandidateFormsApplicationSections = (id?: number | string) => {
  const { data, isLoading } = useGetCandidateForms(id)

  const applicationFormSections = useMemo(
    () =>
      data?.reduce<ApplicationSectionInterface[][]>((acc, item) => {
        if (item.source?.id === 'application' && item.application_form_data?.sections) {
          acc.push(item.application_form_data?.sections)
        }

        return acc
      }, []) || [],
    [data],
  )

  return { applicationFormSections, isLoading }
}

export const useGetCandidateDocuments = (id?: number | string) =>
  useFetch<CandidateDocumentInterface[]>(
    id ? `/interviews/candidates/${id}/files` : null,
    'v1',
  )

export const useGetCandidateAllDocuments = (id?: number | string) =>
  useFetch<FileInterface[]>(id ? `/interviews/candidates/${id}/all_files` : null, 'v1')

export const useGetCandidateEmailThreads = (id?: number | string) =>
  useFetch<GetRequestInterface<EmailThreadInterface>>(
    id ? `/interviews/candidates/${id}/threads` : null,
    'v1',
    {
      params: filterSortPageIntoQuery([
        {
          sortBy: 'latest_sent_date_time',
          direction: SORT_DIRECTION.ASC,
        },
      ]),
    },
  )

export const invalidateCandidateEmailThreads = (queryClient: QueryClient, id?: number) =>
  queryClient.invalidateQueries(`/interviews/candidates/${id}/threads`)

export const useGetCandidateEmailThread = (
  candidateId?: number | string,
  id?: number | string,
) =>
  useFetch<EmailThreadInterface>(
    id ? `/interviews/candidates/${candidateId}/threads/${id}` : null,
  )

export const syncCandidateEmails = (id: number | string) =>
  api.post(`/interviews/candidates/${id}/threads/importCandidateEmails`)

export const useGetCandidateStats = (id?: number | string) =>
  useFetch<CandidateStatsInterface>(id ? `/interviews/candidates/${id}/stats` : null)

export const getPrefilledPlaceholdersEmail = (
  templateId: number | string,
  candidateId?: number,
) =>
  api.get<SendCandidateEmailInterface>(
    `/emailTemplates/${templateId}/prefilledTemplate`,
    {
      params: {
        candidate_id: candidateId,
      },
    },
  )

export const getCandidateComments = (roundId: number) =>
  getCommentsAPI({
    baseUrl: `/interviews/rounds/${roundId}/comments`,
  })

export const sendOnlineTestInvite = (roundId: number, stageId: number, testId?: number) =>
  apiWithoutHandling.post(
    `/interviews/rounds/${roundId}/interviewStages/${stageId}/sendTest`,
    {
      online_test_id: testId,
    },
  )

export const setOnlineTestResult = (roundId: number, stageId: number, passed: boolean) =>
  api.put(`/interviews/rounds/${roundId}/interviewStages/${stageId}/reviewTest`, {
    online_test_passed: passed,
  } as Partial<InterviewStageInterface>)

export const getCandidateTimeline = (
  candidateId: number | string,
  { sortBy, filters, page }: FetchDataQueryInterface,
): AxiosPromise<GetRequestInterface<TimelineEventInterface>> =>
  api.get(
    `/interviews/candidates/${candidateId}/timeline`,
    { params: filterSortPageIntoQuery(sortBy, filters, page) },
    'v1',
  )

export const addDocumentRequest: RequestInterfaceNew<CandidateAddDocumentInterface> = {
  get: async ({ id, candidateId }) => {
    return api.get(`/interviews/candidates/${candidateId}/files/${id}`)
  },
  update: async (_, { id, candidateId }, data) => {
    return apiWithoutHandling.put(
      `/interviews/candidates/${candidateId}/files/${id}`,
      data,
    )
  },
  submit: async (data, { id }) => {
    // the BE has been implemented in a weird way, the file category defined by the user select, so we need to check and show the error on the FE
    if (!data.category) {
      // eslint-disable-next-line no-throw-literal
      throw {
        response: {
          data: { category: 'Please select the category' },
          status: 400,
        },
      }
    }

    if (!data.file) {
      // eslint-disable-next-line no-throw-literal
      throw {
        response: {
          data: { file: 'Please upload the file' },
          status: 400,
        },
      }
    }

    return uploadFile(
      data.file,
      String(data.category.id),
      data.category?.id !== 'candidate_resume',
    )
      .catch(e => {
        if (e?.response) {
          throw e
        }
        // for big files the request fails before hitting the BE, that's why e.response is empty
        // eslint-disable-next-line no-throw-literal
        throw {
          response: {
            data: { file: 'File too large' },
            status: 400,
          },
        }
      })
      .then(resp =>
        apiWithoutHandling
          .post(`/interviews/candidates/${id}/add_file`, {
            id: resp.data.id,
            name: data.name,
          })
          .catch(async e => {
            await deleteFile(resp.data.id)
            throw e
          }),
      )
  },
}

export const deleteCandidateDocument = (candidateId: number | string, fileId: number) =>
  api.post(`/interviews/candidates/${candidateId}/remove_file`, { id: fileId })

export const fetchOnlineTestInfo = (id: number) =>
  api.get<OnlineTestInterface>(`/onlinePlatforms/tests/${id}`)

export const useGetBulkHiringStages = (candidateIds?: (string | number)[]) => {
  const { data, ...res } = useFetch<{ hiring_stages: number[] }>(
    candidateIds?.length ? `${API.CANDIDATES_BULK_SEND_ONLINE_TEST}/hiringStages` : null,
    undefined,
    {
      params: {
        candidate_ids: candidateIds?.join(','),
      },
    },
  )
  return {
    ...res,
    data: data?.hiring_stages,
  }
}

export const useGetActiveOnlineTests = (platform?: string | number) =>
  useFetch<IdAndName[]>(
    platform ? `/onlinePlatforms/tests/activeTests` : null,
    undefined,
    {
      params: {
        platform,
      },
    },
  )

export const useGetHiringStages = (filters?: FilterByInterface[]) => {
  return useFetch<GetRequestData<HiringProcessInterface>>(
    `/hiringProcess/hiringStages`,
    undefined,
    {
      params: filterSortPageIntoQuery(undefined, filters),
    },
  )
}

export const useGetSpecialisationHiringStages = (specialisationId?: number | string) => {
  return useFetch<GetRequestData<HiringProcessInterface>>(
    specialisationId ? '/hiringProcess/specialisationHiringStages' : null,
    undefined,
    {
      params: {
        specialisation__id: specialisationId,
        status: Statuses.active,
      },
    },
  )
}

export const useGetActiveSpecialisationHiringProcessList = (
  specialisationId?: number | string,
) => {
  const { data, ...ctx } = useFetch<GetRequestData<SpecialisationHiringProcess>>(
    specialisationId
      ? `/hiringProcess/specialisation/${specialisationId}/hiringProcesses`
      : null,
    undefined,
    {
      params: {
        status: 'active',
      },
    },
  )
  return {
    data: data?.results ?? [],
    ...ctx,
  }
}

export const useGetSpecialisationHiringProcess = (
  specialisationId?: number | string,
  hiringProcessId?: number | string,
) => {
  return useFetch<SpecialisationHiringProcess>(
    specialisationId && hiringProcessId
      ? `/hiringProcess/specialisation/${specialisationId}/hiringProcesses/${hiringProcessId}`
      : null,
  )
}

export const useGetCompanyHiringStage = (companyHiringStageId?: number) =>
  useFetch<HiringProcessInterface>(
    companyHiringStageId
      ? `${API.HIRING_PROCESS}/hiringStages/${companyHiringStageId}`
      : null,
  )

export const useGetRoleHiringStage = (roleHiringStageId?: number) =>
  useFetch<HiringProcessInterface>(
    roleHiringStageId
      ? `${API.HIRING_PROCESS}/roleHiringStages/${roleHiringStageId}`
      : null,
  )

export const useGetSpecialisationHiringStage = (
  specialisationHiringStageId: number | null,
) =>
  useFetch<HiringProcessInterface>(
    specialisationHiringStageId
      ? `${API.HIRING_PROCESS}/specialisationHiringStages/${specialisationHiringStageId}`
      : null,
  )

export const candidateBulkEmailsRequests: TableRequestInterface<CandidateBulkEmailsInterface> =
  {
    getItems: async ({ sortBy, filters, page }) =>
      api.get(`/candidateBulkEmails`, {
        params: filterSortPageIntoQuery(sortBy, filters, page),
      }),
  }

export const candidateBulkArchiveRequests: TableRequestInterface<CandidateBulkArchiveInterface> =
  {
    getItems: async ({ sortBy, filters, page }) =>
      api.get(`/candidateBulkArchive`, {
        params: filterSortPageIntoQuery(sortBy, filters, page),
      }),
  }

export const candidateBulkEditRequests: TableRequestInterface<CandidateBulkEditInterface> =
  {
    getItems: async ({ sortBy, filters, page }) =>
      api.get(`/candidateBulkEdit`, {
        params: filterSortPageIntoQuery(sortBy, filters, page),
      }),
  }

export const candidateBulkMoveStageRequests: TableRequestInterface<CandidateBulkMoveStageInterface> =
  {
    getItems: async ({ sortBy, filters, page }) =>
      api.get(`/candidateBulkMoveToStage`, {
        params: filterSortPageIntoQuery(sortBy, filters, page),
      }),
  }

export const candidateBulkSendOnlineTestRequests: TableRequestInterface<CandidateBulkSendOnlineTestInterface> =
  {
    getItems: async ({ sortBy, filters, page }) =>
      api.get(`/candidateBulkSendOnlineTest`, {
        params: filterSortPageIntoQuery(sortBy, filters, page),
      }),
  }

export const candidateBulkSnoozeRequests: TableRequestInterface<CandidateBulkSnoozeInterface> =
  {
    getItems: async ({ sortBy, filters, page }) =>
      api.get(API.CANDIDATES_BULK_SNOOZE, {
        params: filterSortPageIntoQuery(sortBy, filters, page),
      }),
  }

export const candidateBulkUnsnoozeRequests: TableRequestInterface<CandidateBulkUnsnoozeInterface> =
  {
    getItems: async ({ sortBy, filters, page }) =>
      api.get(API.CANDIDATES_BULK_UNSNOOZE, {
        params: filterSortPageIntoQuery(sortBy, filters, page),
      }),
  }

export const useCheckPublicReEngagementConsentNeeded = (countryId?: number | string) =>
  useFetch<ConsentRequiredInterface>(
    countryId ? `/interviews/applications/isConsentRequired` : null,
    undefined,
    {
      params: {
        country_id: countryId,
      },
    },
  )

export const specialisationsHiringProcessOverviewRequests: TableRequestInterface<SpecialisationsHiringProcessOverviewInterface> =
  {
    getItems: ({ sortBy, filters, page }) =>
      api.get(API.SPECIALISATIONS_HIRING_PROCESS_OVERVIEW, {
        params: filterSortPageIntoQuery(sortBy, filters, page),
      }),
  }

export const specialisationArchiveHiringProcessRound = (id: number) =>
  apiWithoutHandling.post(
    `${API.HIRING_PROCESS}/specialisationHiringStages/${id}/archive`,
  )

export const bulkCreateHiringProcessRound = (
  specialisationIds: number[] | string[],
  hiringStageId: number,
) =>
  api.put(`${API.HIRING_PROCESS}${API.HIRING_STAGES}/${hiringStageId}/bulkCreate`, {
    specialisation_ids: specialisationIds,
  })

export const bulkCreateHiringProcess = (hiringStageIds: number[]) =>
  api.put(API.BULK_CREATE_HIRING_PROCESS, {
    hiring_stage_ids: hiringStageIds,
  })

export const bulkArchiveSpecialisationHiringStages = (
  specialisationHiringStageIds: number[],
) =>
  api.patch(API.BULK_ARCHIVE_SPECIALISATION_HIRING_STAGES, {
    specialisation_hiring_stage_ids: specialisationHiringStageIds,
  })

export const cloneSpecialisationHiringProcess = (
  specialisationId: number | string,
  hiringProcessId: number,
  name: string,
) =>
  api.post<SpecialisationHiringProcess>(
    `/hiringProcess/specialisation/${specialisationId}/hiringProcesses/${hiringProcessId}/clone`,
    undefined,
    {
      params: {
        name,
        only_mandatory: 'True',
      },
    },
  )

export const getSpecialisationHiringProcess = (
  specialisationId: number | string,
  hiringProcessId: number,
) =>
  api.get<SpecialisationHiringProcess>(
    `/hiringProcess/specialisation/${specialisationId}/hiringProcesses/${hiringProcessId}`,
  )

export const addHiringStagesToSpecialisationHiringProcess = (
  data: Partial<HiringProcessInterface>,
) => api.post<HiringProcessInterface>('/hiringProcess/specialisationHiringStages', data)

export const updateSpecialisationHiringProcess = (
  hiringProcessId: number,
  specialisationId: number | string,
  data: Partial<SpecialisationHiringProcess>,
) =>
  apiWithoutHandling.patch(
    `/hiringProcess/specialisation/${specialisationId}/hiringProcesses/${hiringProcessId}`,
    data,
  )

export const hiringProcessesTableRequests = (
  specialisationId: number | string,
): TableRequestInterface<SpecialisationHiringProcess> => ({
  getItems: async ({ sortBy, filters, page }) =>
    api.get(`/hiringProcess/specialisation/${specialisationId}/hiringProcesses`, {
      params: filterSortPageIntoQuery(sortBy, filters, page),
    }),
})

export const createSpecialisationHiringProcessRequest = (
  specialisationId: string,
): RequestInterfaceNew<SpecialisationHiringProcess> => {
  const endpoint = `/hiringProcess/specialisation/${specialisationId}/hiringProcesses`
  return {
    get: async ({ id }) => api.get(`${endpoint}/${id}`),
    update: async (data, { id }) => apiWithoutHandling.patch(`${endpoint}/${id}`, data),
    submit: async data => apiWithoutHandling.post(`${endpoint}`, data),
    delete: async ({ id }) => api.delete(`${endpoint}/${id}`),
  }
}

export const addStageReferencesToHiringProcess = (
  specialisationId: number,
  hiringProcessId: number,
  stageReferences: StageReference,
) =>
  apiWithoutHandling.post<HiringProcessInterface>(
    `/hiringProcess/specialisation/${specialisationId}/hiringProcesses/${hiringProcessId}/addStageReference`,
    stageReferences,
  )
