import React from 'react'
import { Avatar, Box, Group, Item } from '@revolut/ui-kit'
import { useCustomHiringProcessFlag } from '@src/pages/Forms/SpecialisationForm/HiringProcesses/utils'
import { HiringProcessWidget } from '@src/pages/Forms/SpecialisationForm/HiringProcess/HiringProcessWidget'
import { HiringProcessesTable } from '@src/pages/Forms/SpecialisationForm/HiringProcesses/HiringProcessesTable'
import { CloneHiringProcess } from '@src/pages/Forms/SpecialisationForm/HiringProcesses/CloneHiringProcess'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { SpecialisationInterface } from '@src/interfaces/roles'

export const HiringProcessesWidget = () => {
  const { values } = useLapeContext<SpecialisationInterface>()
  const customHiringProcesses = useCustomHiringProcessFlag()
  if (!customHiringProcesses) {
    return <HiringProcessWidget />
  }
  return (
    <Box pt="s-16">
      <Group>
        <Item>
          <Item.Avatar>
            <Avatar useIcon="NearMe" />
          </Item.Avatar>
          <Item.Content>
            <Item.Title>Hiring processes</Item.Title>
            <Item.Description>
              Hiring processes that can be used when hiring for this specialisation
            </Item.Description>
          </Item.Content>
          <Item.Side>
            <CloneHiringProcess
              hiringProcess={values.default_hiring_process}
              name={values.name}
              variant="action"
            />
          </Item.Side>
        </Item>
        <Box pb="s-16" px="s-16">
          <HiringProcessesTable />
        </Box>
      </Group>
    </Box>
  )
}
