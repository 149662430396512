import React, { useRef, useState } from 'react'
import SideBar from '@src/components/SideBar/SideBar'
import {
  ActionButton,
  Box,
  Button,
  Checkbox,
  ErrorWidget,
  Item,
  ItemSkeleton,
  Side,
  StatusPopup,
  TabBar,
  useStatusPopup,
} from '@revolut/ui-kit'
import {
  HiringProcessInterface,
  SpecialisationHiringProcess,
  StageReference,
} from '@src/interfaces/hiringProccess'
import { useTable, useTableReturnType } from '@src/components/Table/hooks'
import {
  addStageReferencesToHiringProcess,
  hiringProcessesStagesRequests,
} from '@src/api/hiringProcess'
import { Virtuoso } from 'react-virtuoso'
import HiringStageDetails from '@src/components/HiringStages/HiringStageDetails'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { useGetSpecialisation } from '@src/api/specialisations'
import { useGetRole } from '@src/api/roles'

type HiringStageProps = {
  disabled: boolean
  hiringStage: HiringProcessInterface
  selected?: HiringProcessInterface[]
  onSelect?: (selected: HiringProcessInterface[]) => void
}

const HiringStage = ({ disabled, hiringStage, selected, onSelect }: HiringStageProps) => {
  const selectedStage = selected?.find(({ id }) => id === hiringStage.id)
  return (
    <Box key={hiringStage.id} my="s-8">
      <Item use="label">
        {onSelect && !!selected && (
          <Item.Prefix>
            <Checkbox
              disabled={disabled}
              checked={!!selectedStage}
              onClick={() => {
                onSelect(
                  selectedStage
                    ? selected.filter(({ id }) => id !== hiringStage.id)
                    : [...selected, hiringStage],
                )
              }}
            />
          </Item.Prefix>
        )}
        <Item.Content>
          <Item.Title>{hiringStage.title}</Item.Title>
          <Item.Description>
            <HiringStageDetails hiringStage={hiringStage} />
          </Item.Description>
        </Item.Content>
      </Item>
    </Box>
  )
}

const Scroller = React.forwardRef<HTMLDivElement>((props, ref) => {
  return <Box ref={ref} {...props} />
})

type CompanyHiringStagesProps = {
  disabled: boolean
  scrollRef: React.RefObject<HTMLDivElement>
  selected: HiringProcessInterface[]
  table: useTableReturnType<HiringProcessInterface, {}, {}>
  onFetchNextPage: () => void
  onChangeSelected: (selected: HiringProcessInterface[]) => void
}

const CompanyHiringStages = ({
  disabled,
  scrollRef,
  selected,
  table,
  onFetchNextPage,
  onChangeSelected,
}: CompanyHiringStagesProps) => {
  return (
    <Box pb="s-32">
      <Virtuoso<HiringProcessInterface>
        data={table.data}
        customScrollParent={scrollRef?.current || undefined}
        overscan={150}
        // https://github.com/petyosi/react-virtuoso/issues/341
        initialItemCount={table.data.length - 1}
        endReached={() => {
          if (table.count > table.data.length) {
            onFetchNextPage()
          }
        }}
        components={{ Scroller }}
        itemContent={(_, hiringStage) => {
          return (
            <HiringStage
              key={hiringStage.id}
              hiringStage={hiringStage}
              disabled={disabled}
              selected={selected}
              onSelect={onChangeSelected}
            />
          )
        }}
      />
      {table.loading && <ItemSkeleton />}
    </Box>
  )
}

type RoleHiringStagesProps = {
  disabled: boolean
  hiringStages?: HiringProcessInterface[]
  loadingHiringStages: boolean
  selected: HiringProcessInterface[]
  onChangeSelected: (selected: HiringProcessInterface[]) => void
}

const RoleHiringStages = ({
  disabled,
  hiringStages,
  loadingHiringStages,
  selected,
  onChangeSelected,
}: RoleHiringStagesProps) => {
  const { values } = useLapeContext<SpecialisationHiringProcess>()
  return (
    <Box>
      {hiringStages?.map(hiringStage => {
        return (
          <HiringStage
            key={hiringStage.id}
            hiringStage={hiringStage}
            disabled={disabled}
            selected={selected}
            onSelect={onChangeSelected}
          />
        )
      })}
      {loadingHiringStages && <ItemSkeleton />}
      {!loadingHiringStages && !hiringStages?.length && (
        <Box my="s-8">
          <ErrorWidget>
            <ErrorWidget.Image />
            <ErrorWidget.Title>
              No Role hiring stages found for {values.specialisation.name}
            </ErrorWidget.Title>
          </ErrorWidget>
        </Box>
      )}
    </Box>
  )
}

const mapToStageReference = (
  stages: HiringProcessInterface[],
  stage_level: 'company' | 'role',
): StageReference[] =>
  stages.map(({ id }) => ({
    stage_id: id,
    stage_level,
  }))

const isFulfilled = <T,>(p: PromiseSettledResult<T>): p is PromiseFulfilledResult<T> =>
  p.status === 'fulfilled'
const isRejected = <T,>(p: PromiseSettledResult<T>): p is PromiseRejectedResult =>
  p.status === 'rejected'

type AddFromHiringStagesBankSidebarProps = {
  onAddHiringStagesFromBank: (hiringStages: HiringProcessInterface[]) => void
  onClose: () => void
}

const AddFromHiringStagesBankSidebar = ({
  onAddHiringStagesFromBank,
  onClose,
}: AddFromHiringStagesBankSidebarProps) => {
  const { values } = useLapeContext<SpecialisationHiringProcess>()
  const [tab, setTab] = useState('company')
  const table = useTable<HiringProcessInterface>(hiringProcessesStagesRequests)
  const [companySelectedStages, setCompanySelectedStages] = useState<
    HiringProcessInterface[]
  >([])
  const scrollRef = useRef<HTMLDivElement>(null)
  const { data: specialisation, isLoading: loadingSpecialisation } = useGetSpecialisation(
    values.specialisation.id,
  )
  const { data: role, isLoading: loadingRole } = useGetRole(specialisation?.role?.id)
  const [roleSelectedStages, setRoleSelectedStages] = useState<HiringProcessInterface[]>(
    [],
  )
  const [loading, setLoading] = useState(false)
  const statusPopup = useStatusPopup()
  const handleAddHiringStages = async () => {
    setLoading(true)
    try {
      const stageReferences = [
        ...mapToStageReference(companySelectedStages, 'company'),
        ...mapToStageReference(roleSelectedStages, 'role'),
      ]
      const results = await Promise.allSettled(
        stageReferences.map(stageReference =>
          addStageReferencesToHiringProcess(
            values.specialisation.id,
            values.id,
            stageReference,
          ),
        ),
      )
      const fulfilledValues = results.filter(isFulfilled).map(({ value }) => value.data)
      const rejectedReasons = results
        .filter(isRejected)
        .map(({ reason }) => JSON.parse(reason.config.data))
      onAddHiringStagesFromBank(fulfilledValues)
      if (rejectedReasons.length) {
        statusPopup.show(
          <StatusPopup variant="error">
            <StatusPopup.Title>
              There was an error adding the following hiring stages from bank
            </StatusPopup.Title>
            <StatusPopup.Description>
              {rejectedReasons.map(data => (
                <HiringStage key={data.id} disabled hiringStage={data} />
              ))}
            </StatusPopup.Description>
          </StatusPopup>,
        )
      } else {
        onClose()
      }
    } catch {
      statusPopup.show(
        <StatusPopup variant="error">
          <StatusPopup.Title>
            There was an error adding hiring stages from bank
          </StatusPopup.Title>
        </StatusPopup>,
      )
    } finally {
      setLoading(false)
    }
  }
  return (
    <SideBar
      isOpen
      variant="wide"
      title="Hiring stages Bank"
      onClose={onClose}
      sideProps={{ scrollRef }}
    >
      <TabBar
        variant="segmented"
        value={tab}
        onChange={newTab => {
          if (newTab) {
            setTab(newTab)
          }
        }}
      >
        <TabBar.Item to="company">Company</TabBar.Item>
        <TabBar.Item to="role">Role</TabBar.Item>
      </TabBar>
      {tab === 'company' && (
        <CompanyHiringStages
          disabled={loading}
          scrollRef={scrollRef}
          selected={companySelectedStages}
          table={table}
          onFetchNextPage={table.fetchNextPage}
          onChangeSelected={setCompanySelectedStages}
        />
      )}
      {tab === 'role' && (
        <RoleHiringStages
          hiringStages={role?.hiring_process_rounds}
          disabled={loading}
          loadingHiringStages={loadingSpecialisation || loadingRole}
          selected={roleSelectedStages}
          onChangeSelected={setRoleSelectedStages}
        />
      )}
      <Side.Actions horizontal>
        <Button variant="secondary" onClick={onClose}>
          Cancel
        </Button>
        <Button
          onClick={handleAddHiringStages}
          pending={loading}
          disabled={
            (!companySelectedStages.length && !roleSelectedStages.length) || loading
          }
        >
          Add stage
        </Button>
      </Side.Actions>
    </SideBar>
  )
}

type AddFromHiringStagesBankProps = {
  onAddHiringStagesFromBank: (hiringStages: HiringProcessInterface[]) => void
}

export const AddFromHiringStagesBank = ({
  onAddHiringStagesFromBank,
}: AddFromHiringStagesBankProps) => {
  const [open, setOpen] = useState(false)
  return (
    <>
      <ActionButton
        useIcon="Library"
        onClick={() => {
          setOpen(!open)
        }}
      >
        Add from hiring stages bank
      </ActionButton>
      {open && (
        <AddFromHiringStagesBankSidebar
          onAddHiringStagesFromBank={onAddHiringStagesFromBank}
          onClose={() => {
            setOpen(false)
          }}
        />
      )}
    </>
  )
}
