import React, { useState } from 'react'
import {
  PageHeader,
  PageHeaderRouterAction,
} from '@src/components/Page/Header/PageHeader'
import { PageWrapper } from '@src/components/Page/Page'
import { PageBody } from '@src/components/Page/PageBody'
import { ROUTES } from '@src/constants/routes'
import { useLapeContext } from '@src/features/Form/LapeForm'
import {
  HiringProcessInterface,
  SpecialisationHiringProcess,
} from '@src/interfaces/hiringProccess'
import { pathToUrl } from '@src/utils/router'
import LapeNewInput from '@src/components/Inputs/LapeFields/LapeNewInput'
import {
  ActionButton,
  BREAKPOINTS,
  Box,
  Button,
  HStack,
  Portal,
  StatusPopup,
  Subheader,
  TableWidget,
  VStack,
  useStatusPopup,
} from '@revolut/ui-kit'
import HiringProcessDetails from '@src/components/HiringProcessDetails/HiringProcessDetails'
import { PageActions } from '@src/components/Page/PageActions'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import {
  createSpecialisationHiringProcessRequest,
  specialisationArchiveHiringProcessRound,
} from '@src/api/hiringProcess'
import Form from '@src/features/Form/Form'
import { useParams } from 'react-router-dom'
import { SpecialisationHiringProcessParams } from '@src/pages/Forms/SpecialisationHiringProcess/types'
import { navigateTo } from '@src/actions/RouterActions'
import { AddFromHiringStagesBank } from '@src/pages/Forms/SpecialisationHiringProcess/AddFromHiringStagesBank'
import HiringStageSpecialisationPreviewForm from '@src/pages/Forms/SpecialisationForm/HiringProcess/HiringStagePreviewForm'
import useIsCommercial from '@src/hooks/useIsCommercial'
import { HiringProcessTable } from '../SpecialisationForm/HiringProcess/HiringProcessTable'
import { Statuses } from '@src/interfaces'

export const GeneralForm = () => {
  const { errors, loading, values, submit } =
    useLapeContext<SpecialisationHiringProcess>()
  const [showHiringStageDetails, setShowHiringStageDetails] =
    useState<HiringProcessInterface>()
  const statusPopup = useStatusPopup()
  const isCommercial = useIsCommercial()
  const backUrl = pathToUrl(ROUTES.FORMS.SPECIALISATIONS.HIRING_PROCESS, {
    id: values.specialisation.id,
  })
  const handleDelete = async (hiringProcessRound: HiringProcessInterface) => {
    const stages = values.hiring_process_stages
    try {
      values.hiring_process_stages = stages?.filter(
        ({ id }) => id !== hiringProcessRound.id,
      )
      await specialisationArchiveHiringProcessRound(hiringProcessRound.id)
    } catch {
      values.hiring_process_stages = stages
      statusPopup.show(
        <StatusPopup variant="error" onClose={statusPopup.hide}>
          <StatusPopup.Title>
            There was an error deleting hiring process rounds
          </StatusPopup.Title>
        </StatusPopup>,
      )
    }
  }
  const navigateToHiringStageForm = (hiringStageId?: number) => {
    navigateTo(
      pathToUrl(ROUTES.FORMS.SPECIALISATION_HIRING_PROCESS.HIRING_STAGE, {
        id: hiringStageId,
        specialisationId: values.specialisation.id,
        hiringProcessId: values.id,
      }),
      {
        specialisation: values.specialisation,
      },
    )
  }
  const handleOrderChange = (hiringProcessRounds: HiringProcessInterface[]) => {
    values.hiring_process_stages = hiringProcessRounds
  }
  const handleShowDetails = (hiringProcessRound: HiringProcessInterface) => {
    setShowHiringStageDetails(hiringProcessRound)
  }
  const actions = (
    <HStack mb="s-16" gap="s-16">
      <AddFromHiringStagesBank
        onAddHiringStagesFromBank={hiringStages => {
          values.hiring_process_stages = [
            ...(values.hiring_process_stages ?? []),
            ...hiringStages,
          ]
        }}
      />
      <ActionButton
        useIcon="Plus"
        onClick={() => {
          navigateToHiringStageForm()
        }}
      >
        Create new stage
      </ActionButton>
    </HStack>
  )
  return (
    <>
      {showHiringStageDetails && (
        <Portal>
          <HiringStageSpecialisationPreviewForm
            id={showHiringStageDetails.id}
            type="specialisation-hiring-process"
            onClose={() => {
              setShowHiringStageDetails(undefined)
            }}
            onSuccessDelete={() => {
              values.hiring_process_stages = values.hiring_process_stages?.filter(
                ({ id }) => id !== showHiringStageDetails.id,
              )
              setShowHiringStageDetails(undefined)
            }}
          />
        </Portal>
      )}
      <PageWrapper>
        <PageHeader
          title={`${values.status === Statuses.draft ? 'Create' : 'Edit'} hiring process`}
          backUrl={backUrl}
          routerAction={PageHeaderRouterAction.navigate}
        />
        <PageBody maxWidth="100%">
          <Box maxWidth={{ all: '100%', md: BREAKPOINTS.md }}>
            <Subheader>
              <Subheader.Title>Hiring process details</Subheader.Title>
            </Subheader>
            <VStack gap="s-16">
              <LapeNewInput label="Name" name="name" placeholder="name" required />
              <LapeNewInput label="Note" name="description" placeholder="note" required />
            </VStack>
            <Subheader>
              <Subheader.Title>Hiring stages</Subheader.Title>
            </Subheader>
            {isCommercial && (
              <>
                {actions}
                <HiringProcessDetails
                  hiringProcessRounds={values.hiring_process_stages!}
                  onDeleteRound={handleDelete}
                  onEditRound={hiringProcessRound => {
                    navigateToHiringStageForm(hiringProcessRound.id)
                  }}
                  onOrderChange={handleOrderChange}
                  onShowRoundDetails={handleShowDetails}
                />
              </>
            )}
          </Box>
          {!isCommercial && (
            <Box width="100%">
              <TableWidget>
                <TableWidget.Actions>{actions}</TableWidget.Actions>
                <TableWidget.Table>
                  <HiringProcessTable
                    hiringProcessRounds={values.hiring_process_stages}
                    hiringProcessErrors={errors.hiring_process_stages}
                    loading={!!loading}
                    onDelete={handleDelete}
                    onOrderChange={handleOrderChange}
                    onRowClick={handleShowDetails}
                  />
                </TableWidget.Table>
              </TableWidget>
            </Box>
          )}
        </PageBody>
        <PageActions>
          <Button use={InternalLink} variant="secondary" to={backUrl}>
            Back
          </Button>
          <NewSaveButtonWithPopup<SpecialisationHiringProcess>
            useValidator
            hideWhenNoChanges={false}
            successText="Hiring process saved successfully"
            onClick={() => {
              values.status = Statuses.active
              return submit()
            }}
            onAfterSubmit={() => {
              navigateTo(backUrl)
            }}
          >
            Submit
          </NewSaveButtonWithPopup>
        </PageActions>
      </PageWrapper>
    </>
  )
}

export const General = () => {
  const params = useParams<SpecialisationHiringProcessParams>()
  return (
    <Form
      api={createSpecialisationHiringProcessRequest(params.specialisationId)}
      forceParams={{
        id: params.hiringProcessId,
      }}
    >
      <GeneralForm />
    </Form>
  )
}
