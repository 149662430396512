import React, { useEffect, useRef, useState } from 'react'
import { RoleInterface, SpecialisationInterface } from '@src/interfaces/roles'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { Button, Dropdown, Flex, Item, Text, Widget } from '@revolut/ui-kit'
import { ChevronDown } from '@revolut/icons'
import { HiringProcessInterface } from '@src/interfaces/hiringProccess'
import { API, selectorKeys } from '@src/constants/api'
import HiringStagePreviewForm from '@src/pages/Forms/SpecialisationForm/HiringProcess/HiringStagePreviewForm'
import { getSelectors } from '@src/api/selectors'
import { OptionInterface } from '@src/interfaces/selectors'
import { Statuses } from '@src/interfaces'
import { ROUTES } from '@src/constants/routes'
import RoleSaveDraftButton from '@src/pages/Forms/RoleForm/Buttons/RoleSaveDraftButton'
import { history, pathToUrl } from '@src/utils/router'
import { formHiringProcessStagesSpecialisationRequest } from '@src/api/hiringProcess'
import { rolesRequests } from '@src/api/roles'
import ContinueRoleButton from '@src/pages/Forms/RoleForm/Buttons/ContintueRoleButton/ContinueRoleButton'
import { PageBody } from '@components/Page/PageBody'
import { PageActions } from '@components/Page/PageActions'
import { Link, useLocation } from 'react-router-dom'
import { getLocationDescriptor } from '@src/actions/RouterActions'
import { updateStagesAfterDelete } from '@src/utils/hiring'
import { handleError } from '@src/api'
import { useNextSpecialisationStepButtonLink } from '@src/pages/Forms/SpecialisationForm/hooks'
import HideIfCommercial from '@src/components/HideIfCommercial/HideIfCommercial'
import { HiringProcessTable } from '@src/pages/Forms/SpecialisationForm/HiringProcess/HiringProcessTable'

const CURRENT_STEP = 'hiring_process'

const HiringProcess = () => {
  const location = useLocation<{ stageId?: number } | undefined>()
  const [loading, setLoading] = useState(false)
  const [openDropdown, setDropdown] = useState(false)
  const [selectedStageId, setSelectedStageId] = useState<number | undefined>(
    location.state?.stageId,
  )
  const [hiringStages, setHiringStages] = useState<OptionInterface[]>([])
  const [role, setRole] = useState<RoleInterface>()
  const ref = useRef<HTMLButtonElement | null>(null)
  const context = useLapeContext<SpecialisationInterface>()
  const { values, errors } = context
  const changeOrder = (d: HiringProcessInterface[]) => {
    values.hiring_process_rounds = d
  }
  const nextLink = useNextSpecialisationStepButtonLink(CURRENT_STEP)

  useEffect(() => {
    fetchHiringStages()
    history.replace({ state: { ...location.state, stageId: undefined } })
  }, [])

  const fetchHiringStages = async () => {
    setLoading(true)
    try {
      const roleResult = values?.role?.id
        ? await rolesRequests.getItem(values?.role?.id)
        : undefined
      const result = await getSelectors(selectorKeys.hiring_stages)
      if (result?.data?.options) {
        setHiringStages(result?.data?.options)
      }

      if (roleResult?.data) {
        setRole(roleResult.data)
      }
    } finally {
      setLoading(false)
    }
  }

  const handleDelete = async (stage: HiringProcessInterface) => {
    try {
      await formHiringProcessStagesSpecialisationRequest.update(
        {
          status: Statuses.archived,
        },
        {
          id: String(stage.id),
        },
      )
      updateStagesAfterDelete(context, stage.id)
    } catch (e) {
      handleError(e)
    }
  }

  const handleRowEdit = (data: HiringProcessInterface) => {
    if (data.id) {
      setSelectedStageId(data.id)
    }
  }

  return (
    <>
      <PageBody maxWidth="100%">
        <Widget p="s-16" width="100%" mb="s-20">
          <HiringStagePreviewForm
            id={selectedStageId}
            type="specialisation"
            onClose={() => setSelectedStageId(undefined)}
            onSuccessDelete={() => {
              updateStagesAfterDelete(context, selectedStageId!)
            }}
          />
          <Flex mb="s-32">
            <Dropdown
              open={openDropdown}
              anchorRef={ref}
              onClose={() => setDropdown(false)}
              autoClose
            >
              <Dropdown.Group>
                <Item
                  color="blue"
                  // @ts-expect-error object works fine here, but UI kit expects string
                  to={getLocationDescriptor(
                    pathToUrl(ROUTES.FORMS.HIRING_STAGES.SPECIALISATION),
                    {
                      specialisation: { id: values.id, name: values.name },
                    },
                  )}
                  use={Link}
                >
                  <Text color="blue">+ Add new</Text>
                </Item>
              </Dropdown.Group>
              {!!role?.hiring_process_rounds?.length && (
                <Dropdown.Group>
                  <Text ml="s-16" color="grey-tone-50" fontSize="small">
                    {values.role?.name ? `${values.role?.name} stages` : 'Role stages'}
                  </Text>
                  {role.hiring_process_rounds.map(stage => {
                    return (
                      <Item
                        key={stage.id}
                        // @ts-expect-error object works fine here, but UI kit expects string
                        to={getLocationDescriptor(
                          pathToUrl(ROUTES.FORMS.HIRING_STAGES.SPECIALISATION),
                          {
                            specialisation: { id: values.id, name: values.name },
                            roleHiringStageId: stage.id,
                          },
                        )}
                        use={Link}
                      >
                        {stage.title}
                      </Item>
                    )
                  })}
                </Dropdown.Group>
              )}

              <Dropdown.Group>
                {!!role?.hiring_process_rounds?.length && (
                  <Text ml="s-16" color="grey-tone-50" fontSize="small">
                    Other stages
                  </Text>
                )}
                {hiringStages.map(stage => {
                  return (
                    <Item
                      key={stage.id}
                      // @ts-expect-error object works fine here, but UI kit expects string
                      to={getLocationDescriptor(
                        pathToUrl(ROUTES.FORMS.HIRING_STAGES.SPECIALISATION),
                        {
                          specialisation: { id: values.id, name: values.name },
                          companyHiringStageId: stage.id,
                        },
                      )}
                      use={Link}
                    >
                      {stage.name}
                    </Item>
                  )
                })}
              </Dropdown.Group>
            </Dropdown>
            <Button
              onClick={() => setDropdown(!openDropdown)}
              mr="s-16"
              ref={ref}
              useIcon={ChevronDown}
              size="sm"
              variant="secondary"
            >
              Add a round
            </Button>
            <HideIfCommercial>
              {values.hiring_playbook_url && (
                <Button
                  use="a"
                  target="_blank"
                  href={values.hiring_playbook_url}
                  useIcon="16/LinkExternal"
                  size="sm"
                  variant="secondary"
                >
                  Hiring Playbook
                </Button>
              )}
            </HideIfCommercial>
          </Flex>
          <Flex flex="1 0">
            <HiringProcessTable
              hiringProcessRounds={values.hiring_process_rounds}
              hiringProcessErrors={errors?.hiring_process_rounds}
              loading={loading}
              onDelete={handleDelete}
              onOrderChange={changeOrder}
              onRowClick={handleRowEdit}
            />
          </Flex>
        </Widget>
      </PageBody>
      <PageActions>
        {values.status === Statuses.draft && (
          <RoleSaveDraftButton
            title="specialisation"
            pathInLocalStorage={pathToUrl(ROUTES.FORMS.SPECIALISATIONS.GENERAL, {})}
            pathAfterSave={ROUTES.FORMS.SPECIALISATIONS.HIRING_PROCESS}
            isNew
            notification={{
              updateMsg: 'Specialisation draft successfully updated.',
              createMsg: 'Specialisation draft successfully created.',
            }}
          />
        )}
        <ContinueRoleButton
          api={API.SPECIALISATIONS}
          type="specialisation"
          step={CURRENT_STEP}
          to={nextLink}
          disabled={!values?.hiring_process_rounds?.length}
        />
      </PageActions>
    </>
  )
}

export default HiringProcess
